import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Gallery from '@browniebroke/gatsby-image-gallery'
import Layout from '../layouts/en'
import Title from '../components/Titles/Title'
import PublicationCard from '../components/PublicationCard'
import SubSubTitle from '../components/Titles/SubSubTitle'

const lang = 'en'

const ProjectTemplate = ({ data, location }) => {
  const { project } = data
  const translations = project.translations.find((t) => t.language === lang)
  const videoId = (url) => {
    const regExp =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch?v=|&v=)([^#&?]*).*/
    const match = url.match(regExp)
    if (match) return match[2]
    return ''
  }
  const images = project.images.map((img) => img.localFile.childImageSharp)

  return (
    <Layout location={location}>
      <Helmet title={`${translations.title}`} />
      <section className="relative">
        <Title>{translations.title}</Title>
        <div className="flex flex-col bg-gray-200 md:flex-row">
          <div className="inline-block w-full md:w-[400px] h-[300px] sm:h-auto">
            {project.images &&
            project.images[0] &&
            project.images[0].localFile &&
            project.images[0].localFile.childImageSharp ? (
              <GatsbyImage
                image={getImage(project.images[0].localFile.childImageSharp)}
                className="min-w-full min-h-full"
                imgStyle={{
                  objectFit: 'cover',
                  objectPosition: '50% 50%',
                }}
                alt="Project image"
              />
            ) : (
              <div className="min-w-full min-h-full">Image not found</div>
            )}
          </div>
          <div className="flex flex-col flex-1 p-10">
            <SubSubTitle>Project Description</SubSubTitle>
            <article
              className="flex-1 pr-3 text-sm leading-relaxed font-text"
              dangerouslySetInnerHTML={{ __html: translations.description }}
            />
          </div>
        </div>
      </section>
      <div className="flex flex-col mx-10 mb-8 sm:mx-20 sm:flex-row">
        {translations.additional_info &&
        translations.additional_info.length > 0 ? (
          <section className="w-full pr-8 mt-8 md:w-6/12">
            <SubSubTitle>Additional Information</SubSubTitle>
            <p
              className="leading-relaxed"
              dangerouslySetInnerHTML={{ __html: translations.additional_info }}
            />
          </section>
        ) : null}
        {project.partners && project.partners.length > 0 ? (
          <section className="w-full mt-8 md:w-6/12">
            <SubSubTitle>Project Partners</SubSubTitle>
            <div className="flex flex-wrap">
              {project.partners.map((p) => (
                <a className="text-black underline " href={p.link}>
                  {p.logo &&
                  p.logo.localFile &&
                  p.logo.localFile.childImageSharp ? (
                    <GatsbyImage
                      image={getImage(p.logo.localFile.childImageSharp)}
                      className="w-[120px] h-[60px] md:w-[200px] md:h-[100px] mr-6"
                      imgStyle={{
                        objectFit: 'contain',
                        height: '100%',
                      }}
                      alt={p.name + ' Logo'}
                    />
                  ) : (
                    <span>{p.name}</span>
                  )}
                </a>
              ))}
            </div>
          </section>
        ) : null}
      </div>
      {project.images && project.images.length > 0 && (
        <section className="mx-10 mb-8 sm:mx-20">
          <SubSubTitle>Images</SubSubTitle>
          <Gallery images={images} />
        </section>
      )}
      {project.youtube_link && (
        <section className="mx-10 mb-8 sm:mx-20">
          <SubSubTitle>Video</SubSubTitle>
          <div className="relative w-full pt-[56.25%]">
            <iframe
              title="Project Video"
              className="absolute top-0 left-0 w-full h-full"
              src={`https://www.youtube-nocookie.com/embed/${videoId(
                project.youtube_link
              )}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </section>
      )}
      {project.publications.length > 0 ? (
        <section className="mx-10 mb-8 sm:mx-20">
          <SubSubTitle>Publications</SubSubTitle>
          {project.publications.map((pub) => (
            <PublicationCard content={pub} lang={lang} />
          ))}
        </section>
      ) : null}
      {project.members.length > 0 ? (
        <section className="mx-10 mb-8 sm:mx-20">
          <SubSubTitle>Contributing Staff</SubSubTitle>
          {project.members.map((m) => {
            const link = lang === 'de' ? '/de' : ''
            const name = m.name.replace(/\s+/g, '-').toLowerCase()
            return (
              <p className="mb-2">
                <a
                  className="text-yellow-600 underline"
                  href={link + '/team/' + name}
                >
                  {m.name}
                </a>
              </p>
            )
          })}
        </section>
      ) : null}
    </Layout>
  )
}

export default ProjectTemplate

export const pageQuery = graphql`
  query ($id: String!) {
    project: directusProject(id: { eq: $id }) {
      title
      id
      translations {
        additional_info
        description
        language
        title
      }
      year
      type
      youtube_link
      images {
        localFile {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
            gatsbyImageData(width: 500)
          }
        }
      }
      partners {
        name
        link
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 200)
            }
          }
        }
      }
      publications {
        id
        slug
        authors
        title
        publisher
        publication_date
        link
        type
        bibtex_entry
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 200)
            }
          }
        }
      }
      members {
        name
      }
    }
  }
`
